var def = {
    regExps: [
        //eslint-disable-next-line
        /^([^,，]+)[,，\s]+([A-Z]{2})([\-\sA-Z]+)?[,，\s]+([A-Z0-9\s\-]{5,10})([\s]+US)?$/i,
        //eslint-disable-next-line
        /^(联系方式：[+]*[0-9\s\-\(\)]|[+]*[0-9\s\-\(\)])+(ext.[0-9\s]+)?$/i,
        //eslint-disable-next-line
        /^([A-Z0-9_\-\.])+\@([A-Z0-9_\-\.])+\.([A-Z]{2,4})$/i
    ],
    test: line => def.regExps.findIndex(a => a.test(line))
};

export default {
    /**
     * 转换地址
     * @param {*} content 
     * @returns 
     */
    parse(content) {
        var lines = (content || '').trim().split('\n').map(a => a.trim()),
            data = {};

        if (content.indexOf("联系人")) {
            lines.forEach((line, index) => {
                switch (def.test(line.trim())) {
                    case 0:
                        data.city = RegExp.$1;
                        data.state_code = RegExp.$2;
                        data.postal_code = RegExp.$4.trim();

                        (index > 0) && (data.name = lines[0].trim());
                        (index > 1) && (data.address1 = lines[1].trim());
                        (index > 2) && (data.address2 = lines[2].trim());
                        break;
                    case 1:
                        data.phone_number = RegExp.$_.trim();
                        break;
                    case 2:
                        data.email = RegExp.$_.trim();
                        break;
                    default:
                        //data.name && (data.country_code = (matchCountry(line) || {}).short);
                        break;
                }
            });
        } else {
            lines.forEach((line, index) => {
                switch (def.test(line.trim())) {
                    case 0:
                        data.city = RegExp.$1;
                        data.state_code = RegExp.$2;
                        data.postal_code = lines[2].trim().replace(/邮编：/, "");

                        (index > 0) && (data.name = lines[0].trim().replace(/联系人：/, ""));
                        (index == 3) && (data.address1 = lines[3].trim().replace(/地址：/, ""));
                        (index == 4) && (data.address2 = lines[4].trim());
                        break;
                    case 1:
                        data.phone_number = RegExp.$_.trim().replace(/联系方式：/, "");
                        break;
                    case 2:
                        data.email = RegExp.$_.trim().replace(/email:/, "");
                        break;
                    default:
                        //data.name && (data.country_code = (matchCountry(line) || {}).short);
                        break;
                }
            });
        }


        if (data.name) {
            !data.country_code && (data.country_code = 'US');
            
            return data;
        }
    }
}